import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rpp-logo-image',
  standalone: true,
  template: `<img
    alt="Logo RPP"
    src="./assets/images/logo-white.png"
    [style.height]="height"
    [style.width]="width"
    [class]="classStyle"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RPPLogoImageComponent {
  @Input() height = 'auto';
  @Input() width = 'auto';
  @Input() classStyle = '';
}
